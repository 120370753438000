import React, { useEffect } from "react";
import Link from "next/link";
import Footer from "../components/Footer";
import { useGlobal } from "../services/hooks/useGlobal";

export default function error404() {
  const { setPageTitle } = useGlobal();

  useEffect(() => {
    setPageTitle({
      pageTitle: "404: That's an error",
    });

    return () => {
      setPageTitle({
        pageTitle: null,
      });
    };
  }, []);

  return (
    <React.Fragment>
      <section className="py-12">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
              <div className="mb-7 font-size-h1">🙁</div>

              <h2 className="mb-5">404. That's an error.</h2>

              <p className="mb-7 text-gray-500">
                The requested URL was not found on this server. That’s all we
                know.
              </p>

              <Link href="/">
                <a className="btn btn-dark mr-2">Go Home</a>
              </Link>

              <Link href="/#subscribe">
                <a className="btn btn-outline-dark">Subscribe</a>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}
